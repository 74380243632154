import axios from "axios";

export const headers = {
	"Tenant-Access-Token": "Y2I3YjEyODVmYzc0NTJjYThhZDVlZTVjOTEwYTBkNmU=",
	"Tenant-Access-Key": "-i1i3QuLfHtlZQ",
};

export const BASE_URL = "https://immersive-game-india.confirmu.com";

export const api = axios.create({
	baseURL: BASE_URL,
	headers,
});
